import { GetStaticProps, NextPage } from 'next';
import Head from 'next/head';
import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import getItDeliveredImage from '@/assets/home/hero/get-it-delivered.jpg';
import pickItUpImage from '@/assets/home/hero/pick-it-up.jpg';
import homepageThumbnail from '@/assets/home/homepage-social-media-thumbnail.jpg';
import { BecomeAHost } from '@/components/home/BecomeAHost';
import { Hero } from '@/components/home/Hero';
import { Recommendations } from '@/components/home/Recommendations';
import { SectionWrapper } from '@/components/home/SectionWrapper';
import { SeoLinks } from '@/components/home/SeoLinks';
import { SignatureDestinations } from '@/components/home/SignatureDestinations';
import HeadMeta from '@/components/static-pages/HeadMeta';
import { FavouriteListings } from '@/components/ui/FavouriteListings';
import { NationalParks } from '@/components/ui/NationalParks/NationalParks';
import { RecentlyViewedRentalsCarousel } from '@/components/ui/RecentlyViewed';
import { signatureDestination } from '@/constants/home/signatureDestinationsAndParks';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useGeoDataMyIp } from '@/hooks/useGeoDataMyIp';
import { useSectionIsInView } from '@/hooks/useSectionIsInView';
import { setHeaderSearchFilter } from '@/redux/modules/globalHeader';
import { ESearchFilterCategory } from '@/services/analytics/search/types';
import { getSeoLinks, TSeoHomeSection } from '@/services/getHomePageData';
import { isProdDeploy } from '@/utility/isSSR';

interface IProps {
  isHomePage: boolean;
  isNewHomepage: boolean;
  locale: string;
  seoHomeData: TSeoHomeSection[];
}

const HomePage: NextPage<IProps> = ({ seoHomeData, locale }) => {
  const intl = useIntl();
  const isProd = isProdDeploy();
  const dispatch = useDispatch();

  const { isMobile } = useBreakpoint();

  const { data: myIpGeoData } = useGeoDataMyIp();

  const myIPAddress =
    myIpGeoData?.city && myIpGeoData?.state
      ? `${myIpGeoData.city}, ${myIpGeoData.state}`
      : undefined;

  useEffect(() => {
    if (myIPAddress) {
      dispatch(
        setHeaderSearchFilter({
          address: myIPAddress,
        }),
      );
    }
  }, [myIPAddress, dispatch]);

  const [nationalParksSectionRef, nationalParksInView] = useSectionIsInView();
  const [signatureDestinationsSectionRef, signatureDestinationsInView] = useSectionIsInView();
  const [becomeAHostSectionRef, becomeAHostInView] = useSectionIsInView();
  const [seoLinksSectionRef, seoLinksInView] = useSectionIsInView();

  useEffect(() => {
    if (nationalParksInView) {
      document.body.style.setProperty('--page-content-bg-color', '#D4DBD9');
      document.body.style.setProperty('--page-header-bg-color', '#D4DBD9');
      document.body.style.setProperty('--page-footer-bg-color', '#D4DBD9');
      return;
    }

    if (signatureDestinationsInView) {
      document.body.style.setProperty('--page-content-bg-color', '#C8D7BA');
      document.body.style.setProperty('--page-header-bg-color', '#C8D7BA');
      document.body.style.setProperty('--page-footer-bg-color', '#C8D7BA');
      return;
    }

    if (seoLinksInView || becomeAHostInView) {
      document.body.style.setProperty('--page-content-bg-color', '#FFFFFF');

      if (isMobile) {
        document.body.style.setProperty('--page-header-bg-color', '#EEECE3');
        document.body.style.setProperty('--page-footer-bg-color', '#EEECE3');
      } else {
        document.body.style.setProperty('--page-header-bg-color', '#FFFFFF');
        document.body.style.setProperty('--page-footer-bg-color', '#FFFFFF');
      }

      return;
    }

    document.body.style.removeProperty('--page-content-bg-color');
    document.body.style.removeProperty('--page-header-bg-color');
    document.body.style.removeProperty('--page-footer-bg-color');
  }, [
    nationalParksInView,
    signatureDestinationsInView,
    becomeAHostInView,
    seoLinksInView,
    isMobile,
  ]);

  // Hardcoded data for now, to be replace with BE data when available
  const signatureDestinations = signatureDestination(intl, isProd);

  return (
    <>
      <HeadMeta
        thumbnailURL={homepageThumbnail.src}
        description={intl.formatMessage({
          defaultMessage:
            'Rent an RV, motorhome, trailer, or campervan from Outdoorsy for your next adventure. Outdoorsy is the #1 most trusted RV rental marketplace in the world',
          id: '/jiUAQ',
        })}
      />

      <Head>
        <style>{`
          body {
            --page-content-bg-color: #EEECE3;
            --page-header-bg-color: #EEECE3;
            --page-footer-bg-color: #EEECE3;
          }
        `}</style>
      </Head>

      <SectionWrapper className="pt-10 lg:pt-20">
        <Hero
          headline={[
            intl.formatMessage({
              id: '7XZCbR',
              defaultMessage: 'Start your adventure',
              description: 'Home > Headline > 1st line',
            }),
            [
              intl.formatMessage({
                id: 'rACY5D',
                defaultMessage: 'with an RV rental',
                description: 'Home > Headline > 2nd line',
              }),
              intl.formatMessage({
                id: 'gIFd7w',
                defaultMessage: 'with a camper van',
                description: 'Home > Headline > 2nd line',
              }),
              intl.formatMessage({
                id: '5KpQy3',
                defaultMessage: 'with a travel trailer',
                description: 'Home > Headline > 2nd line',
              }),
            ],
          ]}
          cards={[
            {
              background: getItDeliveredImage.src,
              title: intl.formatMessage(
                {
                  id: 'Jhm3co',
                  defaultMessage: 'Less set up.{br}More quality time.',
                  description: 'Home > Hero > Predefined searches',
                },
                { br: <br /> },
              ),
              description: intl.formatMessage({
                id: 'DbXJwy',
                defaultMessage: `Love camping, but don't love driving? Get an RV delivered and set up at a location of your choice.`,
                description: 'Home > Hero > Predefined searches',
              }),
              cta: intl.formatMessage({
                id: '+1fAH8',
                defaultMessage: 'Get it delivered',
                description: 'Home > Hero > Predefined searches',
              }),
              url: '/rv-search?=&delivery=true&deliveryStationary=stationary&openStationaryDeliveryModal=true',
              searchFilterCategory: ESearchFilterCategory.GET_IT_DELIVERED,
            },
            {
              background: pickItUpImage.src,
              title: intl.formatMessage(
                {
                  id: 'NcIyH9',
                  defaultMessage: 'Pick it up &{br}hit the road',
                  description: 'Home > Hero > Predefined searches',
                },
                { br: <br /> },
              ),
              description: intl.formatMessage({
                id: 'HmPMvE',
                defaultMessage: `Get ready for the ultimate road trip. Pick up an RV near you, or at a destination of your choice.`,
                description: 'Home > Hero > Predefined searches',
              }),
              cta: intl.formatMessage({
                id: 'HEcT5J',
                defaultMessage: 'Pick it up',
                description: 'Home > Hero > Predefined searches',
              }),
              url: '/rv-search',
              searchFilterCategory: ESearchFilterCategory.PICK_IT_UP,
            },
          ]}
          locale={locale}
          myIPAddress={myIPAddress}
        />
      </SectionWrapper>

      <SectionWrapper>
        <RecentlyViewedRentalsCarousel className="px-6 lg:px-20" />
      </SectionWrapper>

      <SectionWrapper>
        <FavouriteListings className="px-6 lg:px-20" />
      </SectionWrapper>

      <SectionWrapper>
        <Recommendations myIPAddress={myIPAddress} />
      </SectionWrapper>

      <SectionWrapper ref={nationalParksSectionRef}>
        <NationalParks />
      </SectionWrapper>

      <SectionWrapper ref={signatureDestinationsSectionRef}>
        <SignatureDestinations signatureDestinations={signatureDestinations} />
      </SectionWrapper>

      <SectionWrapper ref={becomeAHostSectionRef} className="hidden">
        <BecomeAHost />
      </SectionWrapper>

      {!!seoHomeData.length && (
        <SectionWrapper ref={seoLinksSectionRef}>
          <SeoLinks seoData={seoHomeData} />
        </SectionWrapper>
      )}
    </>
  );
};

export const getStaticProps: GetStaticProps = async ({ locale }) => {
  const seoHomeData = await getSeoLinks(locale);

  return {
    revalidate: 180,
    props: {
      isHomePage: true,
      locale,
      seoHomeData,
    },
  };
};

export default HomePage;
