import { Card, ETextStyleVariant, SkeletonLine, Text } from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React from 'react';

import { IRentalTile } from '@/utility/mapSearchResultToTile';

import { PriceDetails } from '../ListingTile/PriceDetails';
import { RentalInfo } from '../ListingTile/RentalInfo';
import { RentalPrice } from '../ListingTile/RentalPrice';
import { RentalRating } from '../ListingTile/RentalRating';

type TListingTileContentProps = {
  rentalTile: IRentalTile;
  className?: string;
};

export const ModularListingTileContent = ({ rentalTile, className }: TListingTileContentProps) => {
  return (
    <Card.Content className={clsx('w-full py-2', className)}>
      {(() => {
        if (rentalTile.loading) {
          return (
            <Text component="span" variant={ETextStyleVariant.MediumBold}>
              <SkeletonLine className="bg-gray-200" />
            </Text>
          );
        }

        if (rentalTile.title) {
          return (
            <Text component="span" variant={ETextStyleVariant.MediumBold} className="line-clamp-2">
              {rentalTile.title}
            </Text>
          );
        }
      })()}

      <RentalInfo
        title={rentalTile.title}
        loading={rentalTile.loading}
        isStay={rentalTile.isStay}
        {...rentalTile.details}
      />

      <div className="flex items-center justify-between my-1 gap-2">
        {(() => {
          if (rentalTile.loading) {
            return (
              <Text component="span" variant={ETextStyleVariant.LargeBold} className="grow">
                <SkeletonLine className="bg-gray-200" />
              </Text>
            );
          }

          if (rentalTile.price) {
            if (rentalTile.isCampsite) {
              return <RentalPrice currency={rentalTile.currency} {...rentalTile.price} />;
            }

            return <PriceDetails showDiscount {...rentalTile.price} />;
          }
        })()}

        {!!rentalTile.details?.ratingNumber && (
          <RentalRating
            rating={rentalTile.details.ratingNumber}
            quantity={rentalTile.details.ratingQtd}
          />
        )}
      </div>
    </Card.Content>
  );
};
