import { stringify } from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import useSWR from 'swr';

import { ESearchFilters } from '@/constants/searchFilters';
import { useRouter } from '@/hooks/useRouter';
import { getIsAuthenticated, getUserLocale } from '@/redux/selectors/auth/user';
import { getUserCurrency } from '@/redux/selectors/currency';
import { getUserFavorites } from '@/redux/selectors/favorites';
import { EListingSource } from '@/services/analytics/listings/types';
import apiRequest from '@/services/apiRequest';
import { IData as IRentalData } from '@/services/types/search/rentals/id';
import { getCoreApi, getSearchApi } from '@/utility/getCoreApi';
import { getItemFromLocalStorage, setItemInLocalStorage } from '@/utility/localstorage';
import { mapRentalsToTiles } from '@/utility/mapSearchResultToTile';

type TRecentlyViewedRentalsResponse = {
  data: IRentalData[];
};

type TRecentlyViewedRental = {
  created: string;
  rental_id: number;
  updated: string;
  user_id: number;
};

const USER_RECENTLY_VIEWED_RENTALS_URL = `${getCoreApi()}/user-recently-viewed-rentals`;
const RECENTLY_VIEWED_PAGE_LIMIT = 7;

export const useRecentlyViewedRentalTiles = (
  listingSource?: EListingSource,
  skipFetchingRentals: boolean = false,
) => {
  const router = useRouter();
  const currency = useSelector(getUserCurrency);
  const locale = useSelector(getUserLocale);
  const userFavorites = useSelector(getUserFavorites);
  const isAuthenticated = useSelector(getIsAuthenticated);

  // Add local state to manage tiles without refetching
  const [localTiles, setLocalTiles] = useState<IRentalData[]>([]);
  const [hasLocalChanges, setHasLocalChanges] = useState(false);

  const {
    data: savedRecentlyViewedRentalsData,
    isValidating: isValidatingSavedRecentlyViewedRentals,
    mutate: refetchSavedRecentlyViewedRentals,
  } = useSWR<TRecentlyViewedRental[]>(
    [USER_RECENTLY_VIEWED_RENTALS_URL, isAuthenticated],
    (url: string) => (isAuthenticated ? apiRequest<TRecentlyViewedRental[]>({ url }) : []),
  );

  const recentlyViewedRentalIds = useMemo(() => {
    if (savedRecentlyViewedRentalsData === undefined) {
      return [];
    }

    try {
      const localRecentlyViewedRentalIds: number[] =
        JSON.parse(getItemFromLocalStorage('recentlyViewedRentals') || '[]') || [];

      if (isAuthenticated) {
        const savedRecentlyViewedRentalsIds =
          savedRecentlyViewedRentalsData?.map(rental => rental.rental_id) || [];

        // compare savedRecentlyViewedIds with locallyStoredRecentlyViewedRentals
        // and extract ids that are not in savedRecentlyViewedIds
        const newLocalRecentlyViewedIds = localRecentlyViewedRentalIds.filter(
          id => !savedRecentlyViewedRentalsIds.includes(id),
        );

        // combine ids from BE and new local ids
        return [...savedRecentlyViewedRentalsIds, ...newLocalRecentlyViewedIds];
      }

      // if user is not authenticated, return only the ids saved locally
      return localRecentlyViewedRentalIds;
    } catch {
      return [];
    }
  }, [isAuthenticated, savedRecentlyViewedRentalsData]);

  // fetch data only when recentlyViewedRentalIds is not empty
  // allow skipping loading/mapping the rentals if skipFetchingRentals is true
  const {
    data: recentlyViewedRes,
    isValidating: isValidatingRentalData,
    mutate: refetchRentalData,
  } = useSWR<TRecentlyViewedRentalsResponse>(
    `${getSearchApi()}/rentals?${stringify({
      [ESearchFilters.IDS]: recentlyViewedRentalIds.join(','),
      [ESearchFilters.PAGE_LIMIT]: RECENTLY_VIEWED_PAGE_LIMIT,
      [ESearchFilters.RAW_JSON]: true,
      currency,
      locale,
    })}`,
    (url: string) =>
      recentlyViewedRentalIds.length && !skipFetchingRentals
        ? apiRequest<TRecentlyViewedRentalsResponse>({ url })
        : { data: [] },
    {
      onSuccess: data => {
        if (!hasLocalChanges) {
          setLocalTiles(data.data);
        }
      },
    },
  );

  // Add updateLocalTiles function
  const updateLocalTiles = useCallback((updater: (tiles: IRentalData[]) => IRentalData[]) => {
    setHasLocalChanges(true);
    setLocalTiles(currentTiles => {
      const updatedTiles = updater(currentTiles);
      return updatedTiles;
    });
  }, []);

  // Define the mutate function that resets local changes
  const mutate = useCallback(() => {
    setHasLocalChanges(false);
    refetchSavedRecentlyViewedRentals();
    refetchRentalData();
  }, [refetchSavedRecentlyViewedRentals, refetchRentalData]);

  return useMemo(() => {
    const tilesData = hasLocalChanges ? localTiles : recentlyViewedRes?.data || [];

    const tiles = mapRentalsToTiles({
      rentals: tilesData,
      favorites: userFavorites,
      queryParams: router.query,
      hasImageCarousel: true,
      listingSource,
      additionalData: rental => {
        const viewedRental = savedRecentlyViewedRentalsData?.find(
          viewed => viewed.rental_id === rental.id,
        );
        const additionalData = {
          viewed: viewedRental?.created,
          viewedUpdated: viewedRental?.updated,
        };
        return additionalData;
      },
    });

    const isLoading =
      isValidatingSavedRecentlyViewedRentals ||
      (recentlyViewedRentalIds.length > 0 && isValidatingRentalData);

    return {
      recentlyViewedTiles: tiles,
      recentlyViewedIds: recentlyViewedRentalIds,
      isLoading,
      mutate,
      updateLocalTiles,
    };
  }, [
    hasLocalChanges,
    localTiles,
    recentlyViewedRes?.data,
    userFavorites,
    router.query,
    listingSource,
    isValidatingSavedRecentlyViewedRentals,
    recentlyViewedRentalIds,
    isValidatingRentalData,
    mutate,
    updateLocalTiles,
    savedRecentlyViewedRentalsData,
  ]);
};

export const saveRecentlyViewedRental = async (id: number): Promise<TRecentlyViewedRental[]> => {
  const response = await apiRequest<TRecentlyViewedRental[]>({
    url: `${USER_RECENTLY_VIEWED_RENTALS_URL}/${id}`,
    method: 'POST',
  });
  return response;
};

export const deleteRecentlyViewedRental = async (
  ids: number[],
): Promise<TRecentlyViewedRental[]> => {
  // First delete from backend
  const response = await apiRequest<TRecentlyViewedRental[]>({
    url: `${USER_RECENTLY_VIEWED_RENTALS_URL}`,
    method: 'DELETE',
    data: { rentals_ids: ids },
  });

  // Then update local storage
  try {
    const localRecentlyViewedRentalIds: number[] =
      JSON.parse(getItemFromLocalStorage('recentlyViewedRentals') || '[]') || [];

    // Filter out the IDs that need to be deleted
    const updatedLocalIds = localRecentlyViewedRentalIds.filter(id => !ids.includes(id));

    // Save the updated list back to local storage
    setItemInLocalStorage('recentlyViewedRentals', JSON.stringify(updatedLocalIds));
  } catch {
    // Silently handle the error - local storage might be unavailable or corrupted
  }

  return response;
};
