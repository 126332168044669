import { EHeadingStyleVariant, Heading } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';

import { AnimatedHeadline } from '@/components/switchback/AnimatedHeadline';

import css from './Headline.module.css';

type THeadlineProps = {
  value: Array<string | string[]>;
};

export const Headline = ({ value }: THeadlineProps) => {
  return (
    <Heading
      component="div"
      variant={EHeadingStyleVariant.H1Alt}
      className={cn('flex flex-col mb-10 lg:mb-12', css.root)}>
      {value.map((v, index) => {
        if (typeof v === 'string' || v.length === 1) {
          return <span key={index}>{Array.isArray(v) ? v[0] : v}</span>;
        }

        return v.length > 1 ? <AnimatedHeadline key={index} value={v} /> : null;
      })}
    </Heading>
  );
};
