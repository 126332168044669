import cn from 'classnames';
import { useEffect, useMemo, useState } from 'react';

const STAY_STILL_DURATION = 3000;
const ANIMATION_DURATION = 500;

type TAnimatedHeadlineProps = {
  value: string[];
};

export const AnimatedHeadline = ({ value }: TAnimatedHeadlineProps) => {
  const map = useMemo(() => Object.assign({}, value), [value]);
  const [props, setProps] = useState({ index: 0, animated: false });

  useEffect(() => {
    if (props.animated) {
      const timeout = setTimeout(
        () => setProps(p => ({ index: (p.index + 1) % value.length, animated: false })),
        ANIMATION_DURATION,
      );

      return () => clearTimeout(timeout);
    }

    const timeout = setTimeout(
      () => setProps(p => ({ ...p, animated: true })),
      STAY_STILL_DURATION,
    );

    return () => clearTimeout(timeout);
  }, [props, value.length]);

  return (
    <span className="relative flex flex-col overflow-clip">
      <span className="w-0">&nbsp;</span>

      <span
        className={cn('absolute translate-y-0', {
          '!-translate-y-full duration-500 transition-transform': props.animated,
        })}>
        {map[props.index]}
      </span>

      <span
        className={cn('absolute translate-y-full', {
          '!translate-y-0 duration-500 transition-transform': props.animated,
        })}>
        {map[(props.index + 1) % value.length]}
      </span>
    </span>
  );
};
