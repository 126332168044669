import { memo, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import BookingCardContainer from '@/components/trips/BookingCard/BookingCardContainer';
import { EBookingsFilters } from '@/constants/booking';
import { selectAuthenticated } from '@/redux/modules/auth/selectors';
import { fetchBookings } from '@/redux/modules/bookings/actions';
import { upcomingTripSelector } from '@/redux/selectors/booking';

import { AnimateInsertion } from '../../switchback/AnimateInsertion';
import { SectionHeading } from '../SectionHeading';

interface ITrips {
  locale: string;
}

export const Trips = memo(({ locale }: ITrips) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const upcomingTrip = useSelector(upcomingTripSelector);
  const isAuthenticated = useSelector(selectAuthenticated);

  useEffect(() => {
    if (!isAuthenticated) return;
    dispatch(fetchBookings(EBookingsFilters.UPCOMING, locale));
  }, [isAuthenticated, dispatch, locale]);

  if (!isAuthenticated || !upcomingTrip?.length || !upcomingTrip[0]) {
    return null;
  }

  return (
    <AnimateInsertion>
      <div className="flex flex-col mb-10 gap-4 lg:gap-6 lg:mb-20">
        <SectionHeading
          text={intl.formatMessage({
            defaultMessage: 'Get ready for your upcoming trip',
            id: 'YwjItr',
          })}
        />

        <div className="grid gap-6 lg:grid-cols-2">
          <BookingCardContainer bookings={[upcomingTrip[0]]} isNewHomepage />
        </div>
      </div>
    </AnimateInsertion>
  );
});

Trips.displayName = 'Trips';
