import { Carousel } from '@outdoorsyco/bonfire';
import cn from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  ModularListingTile,
  ModularListingTileContent,
  ModularListingTileMedia,
} from '@/components/ui/ModularListingTile';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useRecentlyViewedRentalTiles } from '@/hooks/useRecentlyViewedRentalTiles';
import {
  trackListingImageViewedEvent,
  trackListingSelectedEvent,
} from '@/services/analytics/listings';
import { EListingSource } from '@/services/analytics/listings/types';
import { OptimizelyFlags, useExperimentIsEnabled } from '@/services/experiments';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

import { SectionHeading } from '../../home/SectionHeading';
import { AnimateInsertion } from '../../switchback/AnimateInsertion';
import { ViewMoreRentalsTile } from '../ViewMoreRentalsTile';
import css from './RecentlyViewedRentals.module.css';

type TRecentlyViewedRentalsCarouselProps = {
  className?: string;
};

export const RecentlyViewedRentalsCarousel = ({
  className,
}: TRecentlyViewedRentalsCarouselProps) => {
  const intl = useIntl();
  const { isAboveDesktop, isAboveTablet, isAboveXXXXL } = useBreakpoint();

  const { recentlyViewedTiles } = useRecentlyViewedRentalTiles(EListingSource.HOMEPAGE);
  const maxRecentlyViewed = isAboveXXXXL ? 7 : 5;

  const superhostBadgeEnabled = useExperimentIsEnabled(OptimizelyFlags.SUPERHOST_BADGE);

  const onClickRental = (rental: IRentalTile, index: number) => {
    trackListingSelectedEvent({
      rental,
      eventSource: EListingSource.HOMEPAGE,
      listingPlacement: index + 1,
    });
  };

  const onChangeImage = (rental: IRentalTile, index: number, nextIndex: number) => {
    trackListingImageViewedEvent(rental, EListingSource.HOMEPAGE, index + 1, nextIndex + 1);
  };

  if (!recentlyViewedTiles || !recentlyViewedTiles.length) {
    return null;
  }

  const hasViewMoreTile = recentlyViewedTiles.length >= 1;
  const carouselContent = recentlyViewedTiles.slice(0, maxRecentlyViewed).map((rental, index) => (
    <ModularListingTile
      key={`recent-rental-${index}`}
      rentalTile={rental}
      target={isAboveDesktop ? '_blank' : undefined}
      onClick={() => onClickRental(rental, index)}>
      <ModularListingTileMedia
        rentalTile={rental}
        {...(rental.loading
          ? {}
          : {
              largeSourceSize: false,
              onSlideChange: nextIndex => onChangeImage(rental, index, nextIndex),
            })}
        className="aspect-[407/236] !h-full [&>div]:!pb-0"
        showFirstImageOnly={!isAboveDesktop}
        autoRotateOnHover
        showSuperhost={superhostBadgeEnabled}
        showGuestFavorite
        wishlistingEventData={{
          index,
          eventSource: EListingSource.HOMEPAGE,
        }}
      />

      <ModularListingTileContent rentalTile={rental} />
    </ModularListingTile>
  ));

  if (hasViewMoreTile) {
    carouselContent.push(
      <ViewMoreRentalsTile
        href="/rv-search"
        text={intl.formatMessage({ defaultMessage: 'Explore more nearby', id: 'seAhs4' })}
      />,
    );
  }

  return (
    <AnimateInsertion>
      <div className={cn('mx-auto max-w-xxxxl', className)}>
        <div className="pb-6 md:pb-0 md:-mb-10">
          <SectionHeading
            text={intl.formatMessage({
              defaultMessage: 'Recently viewed',
              id: 'MSvvwO',
            })}
          />
        </div>

        {!isAboveTablet && (
          <div className="-mx-6">
            <Swiper
              className={`${css.recentlyViewedMobileCarousel}`}
              slidesPerView={1.2}
              centeredSlides={true}
              spaceBetween={16}
              effect="coverflow"
              coverflowEffect={{
                rotate: 0,
                stretch: 0,
                depth: 100,
                modifier: 1,
                slideShadows: true,
              }}>
              {carouselContent.map((slide, index) => (
                <SwiperSlide key={index}>{slide}</SwiperSlide>
              ))}
            </Swiper>
          </div>
        )}

        {isAboveTablet && (
          <div className={`${css.recentlyViewedDesktopCarousel}`}>
            <Carousel
              content={carouselContent}
              freeMode
              slidesPerView={isAboveXXXXL ? 4 : 3}
              slidesPerGroup={isAboveXXXXL ? 4 : 3}
              spaceBetween={32}
            />
          </div>
        )}
      </div>
    </AnimateInsertion>
  );
};
