import {
  Avatar,
  Button,
  EAvatarSize,
  EButtonColorVariant,
  GeneralBrandmarkIcon,
} from '@outdoorsyco/bonfire';
import { clsx } from 'clsx';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ResponsiveImage from '@/components/utility/ResponsiveImage/ResponsiveImage';
import { useFormattedDate } from '@/hooks/useFormattedDate';
import { trackRenterBookingSelected } from '@/services/analytics/booking-management';
import { ERentalType } from '@/services/analytics/types';
import { IBooking } from '@/services/types/booking/details';
import formatImageURL from '@/utility/formatImageURL';
import { getCampgroundLocationText } from '@/utility/mapSearchResultToTile';

import CustomLink, { LinkVariants } from '../../switchback/Link/Link';
import { BookingStatusLabel } from './BookingStatusLabel/BookingStatusLabel';

interface ICardProps {
  booking: IBooking;
  isNewHomepage?: boolean;
}

const BookingCard: React.FC<ICardProps> = ({ booking, isNewHomepage = false }) => {
  const intl = useIntl();
  const intlFormattedDate = useFormattedDate();
  const {
    rental_summary,
    from,
    to,
    status,
    id,
    can_renter_review,
    renter_did_review: renterReview,
    renter_approved: renterAccepted,
    display_status: statusDisplay,
    sibling_booking_group_id,
    deliverable_campground,
  } = booking || {};

  const rentalName = rental_summary?.name;
  const rentalLocation = rental_summary?.location;
  const photoUrl = rental_summary?.primary_image_url;
  const fromDate = intlFormattedDate(from, {
    day: 'numeric',
    month: 'short',
  });
  const tripDate = `${fromDate} - ${intlFormattedDate(to, {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  })}`;

  const isOutdoorsyStayBooking = Boolean(sibling_booking_group_id);
  const campgroundLocationText =
    deliverable_campground &&
    getCampgroundLocationText(deliverable_campground, isOutdoorsyStayBooking);

  const getCtaButton = (status: string, isReview: boolean, id: number) => {
    if (!isReview && status === 'returned' && can_renter_review) {
      return (
        <Button
          className="justify-center mr-2 -mt-1 sm:mr-0 md:mr-0"
          test-id="review-btn"
          variant={EButtonColorVariant.Secondary}
          label={intl.formatMessage({ defaultMessage: 'Review trip', id: '2csHn7' })}
          href={`/reviews/new/${id}`}
          fullWidth
        />
      );
    }

    //checking case for Resume checkout button
    if (status === 'negotiating' && !renterAccepted) {
      return (
        <Button
          className="justify-center w-full mr-0 -mt-1 sm:w-auto"
          test-id="resume-btn"
          variant={EButtonColorVariant.Primary}
          label={intl.formatMessage({ defaultMessage: 'Resume checkout', id: 'j5wWht' })}
          href={`/checkout/${id}`}
        />
      );
    }
    return <p className="w-full h-auto mr-0 -mt-1 sm:h-8" />;
  };

  const handleTrackRenterBookingSelected = useCallback(
    () =>
      trackRenterBookingSelected({
        bookingID: id,
        rentalType: (() => {
          if (booking.rental_summary?.campsite_summary)
            return booking.rental_summary.campsite_summary.type || ERentalType.CAMPSITE;
          if (booking.rental_summary?.stay_summary) ERentalType.STAY;
          return ERentalType.RV;
        })() as ERentalType,
        isCampground: !!booking.rental_summary?.campsite_summary,
        tripStatus: booking.status,
        bookingLocation: booking.rental_summary?.location || '',
        isDelivery: Boolean(booking.delivery.stationary),
        bookingReturnDate: parseInt((new Date(booking.to).getTime() / 1000).toFixed(0)),
        bookingDepartureDate: parseInt((new Date(booking.from).getTime() / 1000).toFixed(0)),
      }),
    [booking, id],
  );

  return (
    <article
      className="flex flex-col h-auto mb-4 w-100 sm:flex-row sm:mb-0"
      onClick={() => handleTrackRenterBookingSelected()}
      data-testid="booking-card">
      <CustomLink
        naked
        className="flex flex-col w-full p-4 sm:flex-row hover:no-underline"
        href={`/trips/${id}`}
        variant={LinkVariants.noUnderline}>
        {!!photoUrl && (
          <div className="relative w-full h-40 mb-2 overflow-hidden shrink-0 rounded-md sm:w-48 sm:h-36">
            <ResponsiveImage
              className="absolute object-cover w-full h-full top-1/2 left-1/2 transform -translate-y-1/2 -translate-x-1/2"
              src={photoUrl}
              role="presentation"
              alt={rentalName}
              sourceSizes={['landscape768', 'landscape375']}
              sizes="144px, (max-width: 768px) 375px"
            />
            {isOutdoorsyStayBooking && deliverable_campground && (
              <div className="absolute z-10 overflow-hidden border-2 border-white rounded-xl left-4 bottom-4">
                <Avatar
                  ariaLabel={deliverable_campground?.name}
                  defaultBrandIcon={GeneralBrandmarkIcon}
                  imageUrl={
                    deliverable_campground?.primary_image_url &&
                    formatImageURL(deliverable_campground.primary_image_url, 'square128', 'fill')
                  }
                  size={EAvatarSize.Large}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={clsx(
            'items-center content-start w-full h-auto grid grid-rows-auto gap-y-2 sm:pl-2 sm:ml-2 sm:h-36 overflow-hidden',
            { 'sm:content-center': isNewHomepage, 'sm:content-between': !isNewHomepage },
          )}>
          <div className="flex flex-col truncate gap-6" data-testid="card-heading">
            <div
              className={clsx(
                'flex items-center text-gray-700 uppercase autoType200 font-semi-bold font-highlight xl:items-center',
                { 'lg:items-start lg:flex-col xl:flex-row': isNewHomepage },
              )}
              data-testid="card-heading">
              <p className="mr-2">{tripDate}</p>
              <BookingStatusLabel status={status} statusDisplay={statusDisplay?.toUpperCase()} />
            </div>
            <div className="overflow-hidden font-highlight">
              <p className="font-medium text-gray-900 truncate autoType600">
                {isOutdoorsyStayBooking
                  ? intl.formatMessage({ defaultMessage: 'RV + RV site', id: '1VjvOw' })
                  : rentalName}
              </p>
              <p className="mt-1 font-normal text-gray-800 autoType300">
                {isOutdoorsyStayBooking ? campgroundLocationText : rentalLocation}
              </p>
            </div>
          </div>
          {!isNewHomepage && (
            <div className="flex justify-center mt-2 mr-2 sm:mr-0 md:mr-0">
              {getCtaButton(status, renterReview, id)}
            </div>
          )}
        </div>
      </CustomLink>
    </article>
  );
};

export default BookingCard;
