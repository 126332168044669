import { Card, ECardBorderRadiusSize } from '@outdoorsyco/bonfire';
import escapeHtml from 'escape-html';
import React, { AnchorHTMLAttributes, HTMLAttributeAnchorTarget } from 'react';

import Anchor from '@/components/switchback/Anchor/Anchor';
import Link from '@/components/switchback/Link/Link';
import { IRentalTile } from '@/utility/mapSearchResultToTile';

type TListingTileProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  rentalTile: IRentalTile;
  target?: HTMLAttributeAnchorTarget | undefined;
  dataTestId?: string;
  className?: string;
};

export const ModularListingTile = ({
  children,
  rentalTile,
  target,
  dataTestId,
  className,
  ...props
}: TListingTileProps) => {
  const escapedTitle = rentalTile.title && escapeHtml(rentalTile.title);

  const card = (
    <Card
      aria-label={escapedTitle}
      title={escapedTitle}
      showHoverEffect={false}
      withBoxShadow={false}
      withTransparentBackground={true}
      borderRadiusSize={ECardBorderRadiusSize.Large}
      className={className}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      {...props}>
      {children}
    </Card>
  );

  if (rentalTile.linkUrl) {
    return (
      <Link href={rentalTile.linkUrl} legacy passHref>
        <Anchor
          href={rentalTile.linkUrl}
          target={target}
          data-testid={dataTestId}
          onClick={props.onClick}
          onMouseEnter={props.onMouseEnter}
          onMouseLeave={props.onMouseLeave}>
          {card}
        </Anchor>
      </Link>
    );
  }

  return card;
};
