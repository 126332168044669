import { Carousel, ETextStyleVariant, Text } from '@outdoorsyco/bonfire';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Swiper, SwiperSlide } from 'swiper/react';

import {
  DESTINATION_CARDS_SLIDES_CONFIG,
  EDestinationCardClassName,
  ISignatureDestination,
} from '@/constants/home/signatureDestinationsAndParks';
import { useBreakpoint } from '@/hooks/useBreakpoint';
import { useHasBeenMounted } from '@/hooks/useHasBeenMounted';

import { DestinationCard, DestinationCardDescription } from '../../ui/DestinationCard';
import { SectionHeading } from '../SectionHeading';
import css from './SignatureDestinations.module.css';

interface ISignatureDestinations {
  signatureDestinations: ISignatureDestination[];
}

export const SignatureDestinations = ({ signatureDestinations }: ISignatureDestinations) => {
  const intl = useIntl();
  const hasBeenMounted = useHasBeenMounted();
  const { isAboveDesktop, isAboveXXXL } = useBreakpoint();

  let numberOfSlidesPerView = DESTINATION_CARDS_SLIDES_CONFIG.DEFAULT.PER_VIEW;
  let numberOfSlidesPerGroup = DESTINATION_CARDS_SLIDES_CONFIG.DEFAULT.PER_GROUP;
  if (isAboveXXXL) {
    numberOfSlidesPerView = DESTINATION_CARDS_SLIDES_CONFIG.XXXL.PER_VIEW;
    numberOfSlidesPerGroup = DESTINATION_CARDS_SLIDES_CONFIG.XXXL.PER_GROUP;
  }

  const desktopCarouselDescription = intl.formatMessage({
    defaultMessage:
      'Experience the outdoors your way: from RV camping to rustic cabins and luxury glamping.',
    id: 'lo1fm0',
  });

  return (
    <div className="px-6 mx-auto max-w-xxxxl lg:px-20 section-animated-block">
      <SectionHeading
        text={intl.formatMessage({
          defaultMessage: 'Book an Outdoorsy signature stay',
          id: 'hbrBIa',
        })}
        additionalClasses="pb-4 lg:pb-2 xxxl:pb-6"
      />

      <Text className="pb-10 lg:hidden" variant={ETextStyleVariant.SmallRegular}>
        <FormattedMessage
          defaultMessage="Experience the outdoors your way: from RV camping to rustic cabins and luxury glamping."
          id="lo1fm0"
        />
      </Text>

      <div className="-mx-6 lg:hidden">
        <Swiper
          className={css.destinationsSwiper}
          slidesPerView={1.2}
          centeredSlides={true}
          effect="coverflow"
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}>
          {signatureDestinations.map((destination, index) => (
            // Add transition effect only after the component has been mounted to avoid transition on initial render
            <SwiperSlide key={index} className={hasBeenMounted ? 'duration-[0.3s] ease-in' : ''}>
              <DestinationCard
                {...destination}
                imageSizes="85vw"
                isMobile
                cardClassName={EDestinationCardClassName.ODN}
              />
              <DestinationCardDescription
                destinationDescription={destination.destinationDescription}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className={`${css.signatureDestinationsDesktopCarousel}`}>
        {isAboveDesktop && (
          <Carousel
            content={signatureDestinations.map((destination, index) => (
              <div key={index}>
                <DestinationCard
                  {...destination}
                  imageSizes="35vw"
                  cardClassName={EDestinationCardClassName.ODN}
                  isSignatureDestination
                />
                <DestinationCardDescription
                  destinationDescription={destination.destinationDescription}
                  showDescriptionWhileInactive={true}
                />
              </div>
            ))}
            description={desktopCarouselDescription}
            freeMode
            slidesPerView={numberOfSlidesPerView}
            slidesPerGroup={numberOfSlidesPerGroup}
            spaceBetween={16}
            noNavigationArrows={isAboveXXXL}
          />
        )}
      </div>
    </div>
  );
};
