import {
  Card,
  EIconButtonSize,
  EIconButtonVariant,
  ETextStyleVariant,
  GeneralArrowRightIcon,
  IconButton,
  Text,
} from '@outdoorsyco/bonfire';

interface IViewMoreRentalsTileProps {
  href: string;
  text: string;
}

export const ViewMoreRentalsTile = ({ href, text }: IViewMoreRentalsTileProps) => (
  <Card
    href={href}
    withBoxShadow={false}
    withTransparentBackground
    className="flex-1 border border-gray-800 !rounded-[20px] hover:bg-black hover:bg-opacity-10 transition-[background-color] duration-700 ease-in-out">
    <div>
      <Card.Media fullRadius className="!rounded-[20px]">
        <div className="relative w-full aspect-[407/265]" />
      </Card.Media>
      <Card.Footer className="flex flex-col justify-end pb-8">
        <div className="flex items-center justify-between px-6">
          <Text variant={ETextStyleVariant.MediumBold}>{text}</Text>
          <IconButton
            icon={GeneralArrowRightIcon}
            size={EIconButtonSize.Small}
            variant={EIconButtonVariant.Primary}
          />
        </div>
      </Card.Footer>
    </div>
  </Card>
);
