import cn from 'classnames';
import React, { useEffect, useRef, useState } from 'react';

import { EHomeSearchWidgetVariant, HomeSearchWidget } from '@/components/ui/HomeSearchWidget';
import { useBreakpoint } from '@/hooks/useBreakpoint';

// We can't use sticky alone, since we can't render it at the root level.
// Making it fixed only when stuck, is adding some level of unresponsiveness.
// A combination of sticky and fixed is solving the issue, and allowing us
// to have the same behavior and render in the right place.
export const StickySearchWidget = () => {
  const { isAboveTablet } = useBreakpoint();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    if (isAboveTablet || !containerRef.current) return setIsStuck(false);

    const observer = new IntersectionObserver(
      ([entry]) => setIsStuck(!!entry && entry.intersectionRatio < 1),
      { rootMargin: '-1px 0px 0px 0px', threshold: [1] },
    );

    observer.observe(containerRef.current);

    return () => observer.disconnect();
  }, [isAboveTablet]);

  return (
    <div ref={containerRef} className="sticky top-0 z-10 mb-10 md:relative lg:mb-20">
      <div className="-mx-6 md:mx-0 h-[64px] md:h-[48px] lg:h-[100px]">
        <div
          style={{
            backgroundColor: isStuck
              ? 'var(--page-header-bg-color)'
              : 'var(--page-content-bg-color)',
          }}
          className={cn('px-6 py-2 md:p-0 transition-[background-color] duration-700 ease-in-out', {
            'fixed top-0 left-0 right-0 z-1299 shadow-500': isStuck,
          })}>
          <HomeSearchWidget variant={EHomeSearchWidgetVariant.RvRentals} />
        </div>
      </div>
    </div>
  );
};
