import {
  AmenitiesPetsPetFriendlyIcon,
  Chip,
  EChipSizeVariant,
  GeneralHeartUnfilledIcon,
  GeneralTagIcon,
  VehiclesVehiclesCamperVanSimpleIcon,
  VehiclesVehiclesToyHaulerSimpleIcon,
} from '@outdoorsyco/bonfire';
import React, { SVGProps } from 'react';
import { useIntl } from 'react-intl';

export enum ETagSlug {
  GuestFavorite = 'guest-favorite',
  GreatValue = 'great-value',
  Vintage = 'vintage',
  PetFriendly = 'pet-friendly',
  Campervan = 'campervan',
  SmallTrailer = 'small-trailer',
}

type TTagsProps = {
  active: ETagSlug;
  onChange: (tag: ETagSlug) => void;
};

type TRecommendationTag = {
  tag: ETagSlug;
  label: string;
  icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
};

export const Tags = ({ active, onChange }: TTagsProps) => {
  const intl = useIntl();

  const tags: TRecommendationTag[] = [
    {
      tag: ETagSlug.GuestFavorite,
      label: intl.formatMessage({ defaultMessage: 'Popular', id: 'pxF+t0' }),
      icon: GeneralHeartUnfilledIcon,
    },
    {
      tag: ETagSlug.Campervan,
      label: intl.formatMessage({ defaultMessage: 'Camper van', id: 'nddYs1' }),
      icon: VehiclesVehiclesCamperVanSimpleIcon,
    },
    {
      tag: ETagSlug.PetFriendly,
      label: intl.formatMessage({ defaultMessage: 'Pets', id: 'JH2EfS' }),
      icon: AmenitiesPetsPetFriendlyIcon,
    },
    {
      tag: ETagSlug.GreatValue,
      label: intl.formatMessage({ defaultMessage: 'Value', id: 'GufXy5' }),
      icon: GeneralTagIcon,
    },
    {
      tag: ETagSlug.Vintage,
      label: intl.formatMessage({ defaultMessage: 'Vintage', id: '0hWrM1' }),
      icon: VehiclesVehiclesCamperVanSimpleIcon,
    },
    {
      tag: ETagSlug.SmallTrailer,
      label: intl.formatMessage({ defaultMessage: 'Small trailer', id: 'plzc+y' }),
      icon: VehiclesVehiclesToyHaulerSimpleIcon,
    },
  ];

  return (
    <div className="flex px-6 py-4 -mx-6 overflow-auto lg:py-16 gap-2 lg:gap-4 lg:-mx-20 lg:px-20 scrollbar-none section-animated-block">
      {tags.map(tag => {
        return (
          <Chip
            key={tag.tag}
            withBoxShadow={false}
            content={tag.label}
            leftIcon={<tag.icon />}
            active={tag.tag === active}
            onClick={() => onChange(tag.tag)}
            sizeVariant={EChipSizeVariant.Small}
            className="px-4 py-3 bg-white border-white lg:px-6 !h-[38px] lg:!h-[56px] whitespace-nowrap"
          />
        );
      })}
    </div>
  );
};
